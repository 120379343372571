@import url(https://fonts.googleapis.com/css?family=Libre+Caslon+Text:400,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Crimson+Text:wght@400;600&family=Lato&family=Montserrat&family=Open+Sans&family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bellota+Text:wght@300;400;700&family=Monoton&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bellota+Text:wght@300;400;700&family=Monoton&display=swap);
@import url(https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800);
@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url(https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200&family=Poppins:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logo {
  transition: 500ms;
}

.logo:hover {
  -webkit-transform: scale(1.3, 1.3);
          transform: scale(1.3, 1.3);
}
/*           ///////////////////////////////////////////////////////// */

.aaa {
  background-color: #202b37;
}

.txt-color {
  color: #fff1d0;
}

.headerr {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6)),
    url(/static/media/sguni.0336d105.jpg);
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.hero-text-box {
  margin-left: 10px;
  position: relative;
  max-width: 1140px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.hero-text-box h1 {
  margin-top: 0;
  margin-bottom: 20px;
  color: #fff;
  font-size: 400%;
  word-spacing: 10px;
  letter-spacing: 4px;
  line-height: 100px;
}

@media only screen and (max-width: 767px) {
  .hero-text-box {
    /* width: 400px; */
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
  .hero-text-box h1 {
    margin-bottom: 0px;
    font-size: 200%;
    word-spacing: 6px;
    letter-spacing: 3px;
    line-height: 50px;
  }
}

/*  vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv */

.container .image {
  position: absolute;
  width: 100vw;
  height: 100vh;
}

.container .menu-icon {
  position: absolute;
  color: #282b28;
  font-size: 30px;
  left: 42px;
  top: 24px;
  cursor: pointer;
  z-index: 1000;
}

.menu-container {
  height: 0%;
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
  background: #fff1d0;
  overflow: hidden;
  transition: 1s ease-in-out;
}

.menu-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}

.menu-content a {
  padding: 16px;
  text-decoration: none;
  font-size: 18px;
  color: #282b28;
  display: block;
  font-family: sans-serif;
  transition: 0.3s ease-in-out;
}

.menu-content a:hover {
  background-color: #1b998b;
}

.menu-container .closebtn {
  position: absolute;
  top: 36px;
  right: 48px;
  font-size: 30px;
  color: #fff1d0;
  z-index: 1000;
  cursor: pointer;
}

.about-sec {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.chatButton {
  /* position: fixed;
  bottom: 10px;
  right: 10px;
  height: 80px;
  width: 80px; */
  z-index: 10001;
}

df-messenger {
  --df-messenger-button-titlebar-color: #ffffff;
  --df-messenger-button-titlebar-font-color: #000;
}

.chatBox {
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 3000;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.scrolling-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}
.card {
  display: inline-block;
}
.backcolor {
  position: relative;
}

.contains {
  padding: 0;
}
.navbar-cont {
  height: 50px;
}
header {
  position: absolute;
  margin-bottom: 30px;
  left: 0;
  background: #202b37; /*header colour*/
  /* background: #f4ecd6; */
  width: 100%;
  box-sizing: border-box;
  z-index: 20;
  margin: 0;
  font-weight: 700;
}
header .logo {
  color: #fff; /*logo colour*/
  height: 50px;
  line-height: 50px;
  font-size: 24px;
  float: left;
  font-weight: bold;
  margin-left: 10px;
}
header nav {
  float: right;
  padding-right: 0;
  /* padding-right: 100px; */
}
header nav ul {
  margin: 0;
  padding: 0;
  display: -webkit-flex;
  display: flex;
}
header nav ul li {
  list-style: none;
  position: relative;
}
header nav ul li.sub-menu:before {
  content: "";
  font-family: fontAwesome;
  position: absolute;
  line-height: 50px;
  color: #fff; /*arrow colour*/
  right: 5px;
}
header nav ul li.active.sub-menu:before {
  content: "";
}
header nav ul li ul {
  position: absolute;
  left: 0;
  background: #202b37; /*submenu colour*/
  display: none;
}
header nav ul li.active ul {
  display: block;
}
header nav ul li ul li {
  display: block;
  width: 250px;
}

header nav ul li .a {
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
  color: #fff; /*header text colour*/
  text-decoration: none;
  display: block;
}
header nav ul li .a:hover {
  color: #fff;
  background: #ff3c38;
}
.menu-toggle {
  color: #fff;
  float: right;
  line-height: 50px;
  font-size: 24px;
  cursor: pointer;
  display: none;
  margin-right: 10px;
}

.last {
  margin-right: 20px;
}
@media (max-width: 1200px) {
  header {
    padding: 0 0px;
  }
  .menu-toggle {
    display: block;
  }
  header nav {
    position: absolute;
    width: 100%;
    height: calc(170vh - 20px);
    background: #202b37;
    top: 50px;
    left: -100%;
    transition: 0.5s;
    padding-right: 10px;
  }
  header nav.active {
    left: 0;
  }
  header nav ul {
    display: block;
    text-align: left;
  }
  header nav ul li .a {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    /* color: #202b37; */
  }

  header nav ul li.active ul {
    position: relative;
    background: #b7b7a4; /*submenu colour in mobile view*/
    color: #202b37;
  }
  header nav ul li.active ul li {
    /* position: relative;
    background: #b7b7a4;  */
    color: #202b37;
  }
  header nav ul li ul li {
    width: 100%;
  }
}

.lds-ring {
  margin-top: 300px;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* .pdf-modal {
  display: flex;
  justify-content: center;
  align-items: center;
} */
.pdf {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

* {
  /* font-family: "Bellota Text", "cursive"; */
  font-family: "Roboto", sans-serif;
  font-weight: 400;
}
body {
  background-color: #f5f5f3;
  scroll-behavior: smooth;
}
html {
  scroll-behavior: smooth;
}

.banner {
  width: auto;
  height: auto;
  background-color: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.bannner-img {
  width: 100%;
  height: 100px; 
  margin-right: 20px;
  margin-left: 20px; 
}
.contact-info-cont {
  margin: 0;
  margin-top: 0;
  width: 100%;
  background: #fff;
  color: #000;
  padding: 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  font-size: 12px;
}
.phone {
  /* border-left: 1px solid #fff; */
  margin-left: 20px;
  padding: 3px;
  /* color: #fff; */
}
.email {
  /* border-left: 1px solid #fff; */
  margin-left: 20px;
  padding: 3px;
  /* color: #fff; */
}
@media (max-width: 991px) {
  .banner {
    height: auto;
  }
  .bannner-img {
    width: 100%;
  }
}

.cardsss {
  overflow: hidden;
  display: block;
  height: auto;
}

.btn-circle {
  border-radius: 10px;
  transition: 500ms;
  float: right;

  background-color: rgba(248, 22, 22, 0.897);
}

.margingiv {
  margin-top: 20px;
}

.rainbow {
  position: relative;
  z-index: 0;
  width: auto;
  height: auto;
  border-radius: 0px;
  overflow: hidden;
  padding: 2rem;
}

/* =====================Updates==================== */

.switch-btn {
  font-size: 30px;
  color: rgb(0, 0, 0);
  cursor: pointer;
  transition: 0.6s linear;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* IMP CARDS********************************************************************************** */
.cus-div {
  width: 100%;
  position: relative;
}

#skew4 {
  background-color: #202b37;
  /* background: url("../../img/coursebg.jpg"); */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100vw;
  z-index: 9;
  margin-bottom: 0;
}
/* .cus-div:after {
  position: absolute;
  width: 100%;
  height: 100%;
  background: inherit;
  z-index: -1;
  bottom: 0;
  content: "";
}
#skew4:after {
  transform-origin: right bottom;
  transform: skewY(4deg);
} */
/* .vidcon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
} */

.course-title {
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  color: #ffffff;
  margin-top: 5px;
}
.courses-line {
  width: 75px;
  height: 5px;
  background-color: #000000;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 40px;
}
.about-title {
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  color: #000000;
}
.about-line {
  width: 150px;
  height: 5px;
  background-color: #ff0040;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 70px;
}

.courses-title {
  color: #f0ede7;
  text-align: center;
  text-transform: uppercase;
}
.courses-line {
  background-color: #a1989e;
}
.course-title {
  color: #000;
}

.cse-card {
  background-color: #f0ede7;
  /* color: #f0ede7; */
  transition: all 0.2s ease-in;
}

.cse-logo {
  fill: #ec5867;
  transition: all 0.2s ease-in;
}
.cse-card:hover {
  background: #8f949b;
  /* background: #f0ede7; */
  color: #ec5867;
  cursor: pointer;
}

.cse-card:hover .cse-logo {
  fill: #f0ede7;
}
.cse-card:hover .course-title {
  color: #f0ede7;
}

.mech-card {
  background-color: #f0ede7;
  /* color: #f0ede7; */
  transition: all 0.2s ease-in;
}

.mech-logo {
  fill: #ec5867;

  transition: all 0.2s ease-in;
}
.mech-card:hover {
  background: #8f949b;
  /* background: #f0ede7; */
  color: #ec5867;
  cursor: pointer;
}
.mech-card:hover .mech-logo {
  fill: #f0ede7;
}

.civil-card {
  background-color: #f0ede7;
  /* color: #f0ede7; */
  transition: all 0.2s ease-in;
}

.civil-logo {
  fill: #ec5867;

  transition: all 0.2s ease-in;
}
.civil-card:hover {
  background: #8f949b;
  /* background: #f0ede7; */
  color: #ec5867;
  cursor: pointer;
}
.civil-card:hover .civil-logo {
  fill: #f0ede7;
}

.etc-card {
  background-color: #f0ede7;
  /* color: #f0ede7; */
  transition: all 0.2s ease-in;
}

.etc-logo {
  fill: #ec5867;

  transition: all 0.2s ease-in;
}
.etc-card:hover {
  background: #8f949b;
  /* background: #f0ede7; */
  color: #ec5867;
  cursor: pointer;
}
.etc-card:hover .etc-logo {
  fill: #f0ede7;
}

.ele-card {
  background-color: #f0ede7;
  /* color: #f0ede7; */
  transition: all 0.2s ease-in;
}

.ele-logo {
  fill: #ec5867;

  transition: all 0.2s ease-in;
}
.ele-card:hover {
  background: #8f949b;
  /* background: #f0ede7; */
  color: #ec5867;
  cursor: pointer;
}
.ele-card:hover .ele-logo {
  fill: #f0ede7;
}

.fh5co-counters {
  /* padding: 2em 0; */

  position: relative;

  /* background-color: #56cfe1; */
  background-color: #202b37;
  height: 100%;
  text-align: center;
  padding: 3% 1%;
}
.fh5co-counters .row {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.fh5co-counters .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  background: rgba(0, 0, 0, 0.5);
}
.fh5co-counters .counter-wrap {
  border: 1px solid red !important;
}
.fh5co-counters .fh5co-counter {
  font-size: 44px;
  display: block;
  color: white;
  font-family: "Roboto Slab", serif;
  width: 100%;
  font-weight: 400;
  /* margin-bottom: .1em; */
}
.fh5co-counters .fh5co-counter-label {
  color: rgba(255, 255, 255, 0.8);
  font-size: 18px;
  /* margin-bottom: 2em; */
  display: block;
  font-family: "Roboto Slab", serif;
}
.fh5co-counters .icon i {
  font-size: 45px;
  color: #fff;
}

/* #skew2 {
  background: #ff3c38;

  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;

  z-index: 9;
}
.cus-div:after {
  position: absolute;
  width: 100%;
  height: 100%;
  background: inherit;
  z-index: -1;
  bottom: 0;
  content: "";
}
#skew2:after {
  transform-origin: right bottom;
  transform: skewY(-3deg);
  background: #ff3c38;
} */

.css-caro {
  overflow: auto;
  width: 100%;
  /* max-height: 70vh; */
}
.carou-control {
  background-color: transparent;
}

.cus-div {
  padding: 0 0;
}
.imp-section {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-width: 100vw;
}
.imp-title {
  margin-top: 50px;
  color: #ec5867;
  font-weight: 700;
}
.imp-line {
  background-color: #a1989e;
}

.content-wrapper {
  margin: 0 auto;
  margin-top: 10px;
  width: 100vw;
  display: -webkit-flex;
  display: flex;
  /* flex-flow: row wrap; */
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0.5rem;
  height: 110%;
  padding-bottom: 4%;
}

.news-card {
  border: 0px solid aqua;
  margin: 0.5rem;
  position: relative;
  height: 11rem;
  overflow: hidden;
  border-radius: 0.5rem;
  -webkit-flex: 1 1;
          flex: 1 1;
  min-width: 290px;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.5);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}

@media (min-width: 900px) {
  .news-card {
    height: 20rem;
  }
}

.news-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0)
    linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7) 80%);
  z-index: 0;
}

.news-card__card-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  /*     background: rgba(255,0,0,.5); */
}

.news-card__image {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  transition: -webkit-transform 2s ease;
  transition: transform 2s ease;
  transition: transform 2s ease, -webkit-transform 2s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: relative;
  z-index: -1;
}

.news-card__text-wrapper {
  position: absolute;
  bottom: 0rem;
  padding: 1rem;
  color: white;
  /*     background-color: rgba(0, 0, 0, 0.4); */
  transition: background-color 1.5s ease;
}

.news-card__title {
  transition: color 1s ease;
  margin-bottom: 0.5rem;
}

.news-card__post-date {
  font-size: 0.7rem;
  margin-bottom: 0.5rem;
  color: #ccc;
}

.news-card__details-wrapper {
  max-height: 0;
  opacity: 0;
  transition: max-height 1.5s ease, opacity 1s ease;
}

@media (min-width: 900px) {
  .news-card:hover .news-card__details-wrapper {
    max-height: 20rem;
    opacity: 1;
  }
  .news-card:hover .news-card__text-wrapper {
    background-color: rgba(0, 0, 0, 0.6);
  }
  .news-card:hover .news-card__title {
    color: #ff3c38;
  }
  .news-card:hover .news-card__image {
    -webkit-transform: scale(1);
            transform: scale(1);
    z-index: -1;
  }
}

.news-card__excerpt {
  font-weight: 300;
}

.news-card__read-more {
  background: black;
  color: #bbb;
  display: block;
  padding: 0.4rem 0.6rem;
  border-radius: 0.3rem;
  margin-top: 1rem;
  border: 1px solid #444;
  font-size: 0.8rem;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  text-decoration: none;
  width: 7rem;
  margin-left: auto;
  position: relative;
  z-index: 5;
}

.news-card__read-more i {
  position: relative;
  left: 0.2rem;
  color: #888;
  transition: left 0.5s ease, color 0.6s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.news-card__read-more:hover i {
  left: 0.5rem;
  color: #ff3c38;
}

/* body {
  min-height: 100vh;
  display: flex;
  align-items: center;
  background-color: rgb(233, 150, 150);
  font-family: 'Open Sans'
} */

#demo {
  background: linear-gradient(112deg, #ffffff 50%, antiquewhite 50%);
  max-width: 1400px;
  margin-bottom: 30px;
  /* margin: auto; */
  /* transition: all 0.5s; */
  /* max-height: 400px; */
  font-family: "Bellota Text", "cursive";
  font-weight: bolder;
}
.back-set {
  background-color: #202b37;
  padding-top: 30px;
  height: auto;
  padding-bottom: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.carousel-caption {
  position: static;
  position: initial;
  z-index: 10;
  padding: 4rem 4rem;
  color: rgba(0, 0, 0, 0.856);
  text-align: center;
  font-size: 1.2rem;
  /* font-style: italic; */
  font-weight: bolder;
  line-height: 2rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

@media (max-width: 767px) {
  .carousel-caption {
    position: static;
    position: initial;
    z-index: 10;
    padding: 3rem 2rem;
    color: rgba(78, 77, 77, 0.856);
    text-align: center;
    font-size: 0.7rem;
    font-style: italic;
    font-weight: bold;
    line-height: 1.5rem;
  }
}

.carousel-caption img {
  width: 6rem;
  border-radius: 5rem;
  margin-top: 2rem;
}

@media (max-width: 767px) {
  .carousel-caption img {
    width: 4rem;
    border-radius: 4rem;
    margin-top: 1rem;
  }
}

#image-caption {
  font-style: normal;
  font-size: 1rem;
  margin-top: 0.5rem;
}

@media (max-width: 767px) {
  #image-caption {
    font-style: normal;
    font-size: 0.6rem;
    margin-top: 0.5rem;
  }
}

.i {
  background-color: #202b37;
  /* background-color: rgb(223, 56, 89); */
  padding: 1.4rem;
}
@media (max-width: 767px) {
  .i {
    padding: 0.8rem;
  }
}

.carousel-control-prev {
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.carousel-control-next {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.carousel-control-prev,
.carousel-control-next {
  transition: none;
  opacity: unset;
}

/* =========================== */
.aoe-title {
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  color: #f0ede7;
}
.line {
  width: 75px;
  height: 5px;
  background-color: #a1989e;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 70px;
}

.new_row {
  padding: 0 20px;

  width: 100%;
  display: -webkit-flex;
  display: flex;
  margin-bottom: 0;
  border-bottom: #c1c1c1;
  border-left: none;
  border-width: 10px;
}
.section-news {
  padding: 0px 0px;
}
.new_col {
  padding: 0;
  float: left;
  width: 50%;
  margin-top: 0;
}
.news-cont-scrollable {
  height: 300px;
  overflow: scroll;
}
.circular-cont-scrollable {
  height: 300px;
  overflow: scroll;
}

.text-red {
  color: #eb2123 !important;
}
.text-pink {
  color: #f7008a !important;
}

.text-black {
  font-size: 1rem;
  color: #000 !important;
}

.fullbar-item:hover {
  background: #f6f6f6;
}

@media screen and (max-width: 768px) {
  .section-news {
    padding: 10px;
  }
  .new_row {
    /* padding: 0 20px; */
    padding: 0;
    width: 100%;
    display: inline-block;
  }
  .new_col {
    float: left;
    width: 100%;
  }
  .in-news {
    font-size: 1.5rem !important;
  }

  .news-feed-heading {
    font-size: 1.75rem !important;
  }
}

.news-title {
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  color: #ec5867;
}
.news-line {
  width: 150px;
  height: 5px;
  background-color: #a1989e;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.circular-title {
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  color: #ec5867;
}
.circular-line {
  width: 150px;
  height: 5px;
  background-color: #a1989e;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.givmargin {
  margin: 0 30px;
}

@media (max-width: 991px) {
  .givmargin {
    margin: 0 10px;
  }
}

.update-img {
  position: relative;
}
.folll {
  overflow: hidden;
}
/* .cus-div {
  width: 100%;
  position: relative;
}
#skew1 {
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  z-index: 10;
} */
/* @media (max-width: 991px) {
  #skew1 {
    margin-top: 30%;
    padding: 0;
  }
} */
/* .cus-div:after {
  position: absolute;
  width: 100%;
  height: 80%;
  background: inherit;
  z-index: -1;
  bottom: 0;
  content: "";
}

#skew1::after {
  transform-origin: left bottom;
  transform: skewY(3deg);
  background: #ffffff;
  height: 50%;
} */

.reports-title {
  text-transform: uppercase;
  text-align: center;
  font-weight: 600;
  color: #000000;
  padding: 0;
  margin: 0;
}
.reports-line {
  width: 150px;
  height: 5px;
  background-color: #ff0040;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 25px;
}

.section-newsletter {
  height: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.newsletter-subscribe {
  color: #313437;
  background-color: #eff1f4;
  padding: 55px 74px;
  margin: 0;
  min-width: 100vw;
}

.newsletter-subscribe p {
  color: #7d8285;
  line-height: 1.5;
}

.newsletter-subscribe h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 25px;
  line-height: 1.5;
  padding-top: 0;
  margin-top: 0;
  color: inherit;
}

.newsletter-subscribe .intro {
  font-size: 16px;
  max-width: 500px;
  margin: 0 auto 25px;
}

.newsletter-subscribe .intro p {
  margin-bottom: 35px;
}

.newsletter-subscribe form {
  -webkit-justify-content: center;
          justify-content: center;
}

.newsletter-subscribe form .form-control {
  background: #eff1f4;
  border: none;
  border-radius: 3px;
  box-shadow: none;
  outline: none;
  color: inherit;
  text-indent: 9px;
  height: 45px;
  margin-right: 10px;
  min-width: 250px;
}

.newsletter-subscribe form .btn {
  padding: 16px 32px;
  border: none;
  background: none;
  box-shadow: none;
  text-shadow: none;
  opacity: 0.9;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  letter-spacing: 0.4px;
  line-height: 1;
}

.newsletter-subscribe form .btn:hover {
  opacity: 1;
}

.newsletter-subscribe form .btn:active {
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
}

.newsletter-subscribe form .btn-primary {
  background-color: #055ada !important;
  color: #fff;
  outline: none !important;
}

.newsletter {
  color: #0062cc !important;
}
.email-inp {
  border-bottom: 3px solid #0062cc;
  border-width: 3px;
}

* {
  box-sizing: border-box;
}

.swiper-title {
  margin-top: 50px;
  font-weight: 700;
}
.title {
  color: #f0ede7;

  font-weight: 700;
}

.swiper-line {
  background-color: #a1989e;
}

.swiper-cont {
  /* background-color: #ffe53b; */
  background-color: #202b37;
  /* background-image: linear-gradient(147deg, #ffe53b 0%, #fd3838 74%); */
  max-height: 80vh;
  min-height: 80vh;

  font-family: "Fira Sans", sans-serif;
  /* display: flex;
  flex-direction: column; */
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 0;
  margin: 0;
  max-width: 100vw;
  min-width: 100vw;
  padding: 0;
  /* margin-top: 100px; */
}

.blog-slider {
  width: 90%;
  position: relative;
  max-width: 80vw;
  margin: 0;
  background: #f0ede7;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 25px;
  border-radius: 25px;
  height: 400px;
  transition: all 0.3s;
}

@media screen and (max-width: 992px) {
  .swiper-cont {
    /* max-height: 120vh;
    min-height: 120vh; */

    min-height: 135vh;
    max-height: 135vh;
    /* height: 120vh; */
    padding-bottom: 5%;
  }
  .blog-slider {
    max-width: 98vw;
    height: 100vh;
    margin-top: 30%;
  }
}
@media screen and (max-width: 768px) {
  .blog-slider {
    /* min-height: 500px; */
    height: 80vh;
    /* margin: 1% auto; */
    /* margin-top: 5px; */
  }
  .swiper-cont {
    /* margin-bottom: 10%; */
    /* max-height: 100vh;
    min-height: 100vh; */
    /* max-height: 70vh; */
    height: auto;
    overflow: hidden;
  }
}
@media screen and (max-height: 500px) and (min-width: 992px) {
  .blog-slider {
    height: 350px;
  }
}
.blog-slider__item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
@media screen and (max-width: 768px) {
  .blog-slider__item {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}
.blog-slider__item.swiper-slide-active .blog-slider__img img {
  opacity: 1;
  transition-delay: 0.3s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > * {
  opacity: 1;
  -webkit-transform: none;
          transform: none;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(1) {
  transition-delay: 0.3s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(2) {
  transition-delay: 0.4s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(3) {
  transition-delay: 0.5s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(4) {
  transition-delay: 0.6s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(5) {
  transition-delay: 0.7s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(6) {
  transition-delay: 0.8s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(7) {
  transition-delay: 0.9s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(8) {
  transition-delay: 1s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(9) {
  transition-delay: 1.1s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(10) {
  transition-delay: 1.2s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(11) {
  transition-delay: 1.3s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(12) {
  transition-delay: 1.4s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(13) {
  transition-delay: 1.5s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(14) {
  transition-delay: 1.6s;
}
.blog-slider__item.swiper-slide-active .blog-slider__content > *:nth-child(15) {
  transition-delay: 1.7s;
}
.swiper-slide-active .blog-slider__img {
  width: 300px;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  height: 300px;
  /* background-image: linear-gradient(147deg, #fe8a39 0%, #fd3838 74%); */
  box-shadow: 4px 13px 30px 1px rgba(252, 56, 56, 0.2);
  border-radius: 20px;
  -webkit-transform: translateX(-80px);
          transform: translateX(-80px);
}
/* .blog-slider__img:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(147deg, #fe8a39 0%, #fd3838 74%);
  border-radius: 20px;
  opacity: 0.8;
} */
.blog-slider__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  opacity: 0;
  border-radius: 20px;
  transition: all 0.3s;
}
@media screen and (max-width: 768px) {
  .swiper-slide-active .blog-slider__img {
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 90%;
  }

  @media screen and (max-width: 576px) {
    .swiper-slide-active .blog-slider__img {
      width: 95%;
    }
    .swiper-slide-active .blog-slider__img {
      width: 275px;

      height: 250px;
    }
  }
}
@media screen and (max-height: 500px) and (min-width: 992px) {
  .swiper-slide-active .blog-slider__img {
    height: 270px;
  }
}
.blog-slider__content {
  padding-right: 25px;
}
@media screen and (max-width: 768px) {
  .blog-slider__content {
    margin-top: -80px;
    text-align: center;
    padding: 0 30px;
  }
}
@media screen and (max-width: 576px) {
  .blog-slider__content {
    padding: 0;
  }
}
.blog-slider__content > * {
  opacity: 0;
  -webkit-transform: translateY(25px);
          transform: translateY(25px);
  transition: all 0.4s;
}
.blog-slider__code {
  color: #7b7992;
  margin-bottom: 15px;
  display: block;
  font-weight: 500;
}
.blog-slider__title {
  font-size: 24px;
  font-weight: 700;
  color: #0d0925;
  margin-bottom: 20px;
}
.blog-slider__text {
  color: #4e4a67;
  margin-bottom: 30px;
  line-height: 1.5em;
}
.blog-slider__button {
  display: -webkit-inline-flex;
  display: inline-flex;
  background-image: linear-gradient(147deg, #fe8a39 0%, #fd3838 74%);
  padding: 15px 35px;
  border-radius: 50px;
  color: #fff;
  box-shadow: 0px 14px 80px rgba(252, 56, 56, 0.4);
  text-decoration: none;
  font-weight: 500;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  letter-spacing: 1px;
  border: none;
}
@media screen and (max-width: 576px) {
  .blog-slider__button {
    width: 100%;
  }
}
.blog-slider .swiper-container-horizontal > .swiper-pagination-bullets,
.blog-slider .swiper-pagination-custom,
.blog-slider .swiper-pagination-fraction {
  bottom: 10px;
  left: 0;
  width: 100%;
}
.blog-slider__pagination {
  position: absolute;
  z-index: 21;
  right: 20px;
  width: 11px !important;
  text-align: center;
  left: auto !important;
  top: 50%;
  bottom: auto !important;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
@media screen and (max-width: 768px) {
  .blog-slider__pagination {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    left: 50% !important;
    top: 205px;
    width: 100% !important;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
  }
}
.blog-slider__pagination.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 8px 0;
}
@media screen and (max-width: 768px) {
  .blog-slider__pagination.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 5px;
  }
}
.blog-slider__pagination .swiper-pagination-bullet {
  width: 11px;
  height: 11px;
  display: block;
  border-radius: 10px;
  background: #062744;
  opacity: 0.2;
  transition: all 0.3s;
}
.blog-slider__pagination .swiper-pagination-bullet-active {
  opacity: 1;
  background: #fd3838;
  height: 30px;
  box-shadow: 0px 0px 20px rgba(252, 56, 56, 0.3);
}
@media screen and (max-width: 768px) {
  .blog-slider__pagination .swiper-pagination-bullet-active {
    height: 11px;
    width: 30px;
  }
}

.about-wrapper {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 0;
  height: 70vh;
  margin: 40px 0;
}

.info {
  color: #000;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 50px;
  margin: 50px 0;
  border-right: #202b37 solid 5px;
}
.info p {
  font-size: 1rem;
  letter-spacing: 0.1rem;
  line-height: 2rem;
  text-align: justify;
}
.info .title {
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
  color: #202b37;
  margin-bottom: 50px;
}

.solid-sec {
  /* background-color: #202b37; */
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 0rem 2rem 2rem 0rem;

  margin: 50px;
}
.solid-sec img {
  margin: auto;
  width: auto;
  height: 100%;
  border-radius: 2rem;
}

@media (max-width: 1300px) {
  .about-wrapper {
    height: 70vh;
    /* margin: 40px 0; */
    /* padding: 40px; */
  }
  .info {
    padding: 0 30px;
    margin: 30px 0;
    border-right: #202b37 solid 4px;
  }
  .info p {
    font-size: 1rem;
    line-height: 2rem;
  }
  .info .title {
    margin-bottom: 40px;
  }

  .solid-sec {
    /* background-color: #202b37; */
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    border-radius: 0rem 2rem 2rem 0rem;

    margin: 30px;
  }
}

@media (max-width: 1000px) {
  .about-wrapper {
    width: 100%;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin-top: 0;
    height: auto;
    margin: 20px 0px;
    padding: 20px;
  }
  .info {
    padding: 0 30px;
    margin: 30px 0;
    border-right: none;
  }
  .info p {
    font-size: 0.8rem;
    line-height: 1.5rem;
  }
  .info .title {
    margin-bottom: 40px;
  }
  .solid-sec {
    /* background-color: #202b37; */

    margin: 0px;
    padding: 0px;
  }
  .solid-sec img {
    /* margin: 20px; */
    width: 100%;
    height: 70%;
    border-radius: 2rem;
  }
}

/* .top-content {
  margin-top: 40px;
  padding-bottom: 30px;
  overflow-x: hidden;
  
}

.carousel {
  padding: 0px 40px;
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .carousel {
    padding: 0;
  }
  

  .top-content .carousel-control-prev,
  .top-content .carousel-control-next {
    display: none;
  }
  .top-content .carousel-indicators li {
    margin-left: 10px;
    margin-right: 10px;
  }

 
} */

.vid-container {
  width: 100%;
  margin: 40px 0px;
  padding: 10px;
}
.vid {
  width: 30%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.vid iframe {
  width: 100%;
  height: 400px;
}

.vid-wrapper {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}

@media (max-width: 1400px) {
  .vid iframe {
    height: 300px;
  }
}

@media (max-width: 1000px) {
  .vid iframe {
    height: 200px;
  }
}

@media (max-width: 700px) {
  .vid-wrapper {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
  }
  .vid {
    width: 80%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
  }

  .vid iframe {
    width: 100%;
    height: auto;
  }
}

/* .vid:hover {
  background: rgba(255, 255, 255, 0.5);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  cursor: pointer;
} */
/* 
.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px; 
  background: #fff;

  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide .video {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

.img-gallery-container {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Marquee {
  /* background-color: #202b37; */

  width: 100vw;
  box-sizing: border-box;
  padding: 1em;
  color: #fff;
  font-weight: 200;
  display: -webkit-flex;
  display: flex;
  -o-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  overflow: hidden;
  /* border: 3px solid #000; */
}
.mq-cont {
  border: 3px solid #000;
}
.Marquee-content {
  display: -webkit-flex;
  display: flex;
  /* -webkit-animation: marquee 10s linear infinite running;
  -moz-animation: marquee 10s linear infinite running;
  -o-animation: marquee 10s linear infinite running;
  -ms-animation: marquee 10s linear infinite running;
  animation: marquee 10s linear infinite running; */
}
.Marquee-content:hover {
  /* -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  -ms-animation-play-state: paused;
  animation-play-state: paused; */
}
.Marquee-tag {
  width: auto;
  margin: 0 0.5em;
  padding: 0.5em;
  background: rgba(255, 255, 255, 0.1);
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  -o-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -o-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  transition: all 0.2s ease;
}
.Marquee-tag img {
  width: 400px;
}
.Marquee-tag:hover {
  background: rgba(255, 255, 255, 0.5);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  cursor: pointer;
}
@-webkit-keyframes marquee {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
  }
}
@keyframes marquee {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
  }
}

.slide-back {
  background-color: #ffffff;
  color: #fff;
}

/* .recruter {
  margin: 20px 50px;
} */

.aoe-title {
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  color: #f0ede7;
}
.line {
  width: 75px;
  height: 5px;
  background-color: #a1989e;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 70px;
}

.gallery-item {
  /* Minimum width of 24rem and grow to fit available space */
  -webkit-flex: 1 0 24rem;
          flex: 1 0 24rem;
  /* Margin value should be half of grid-gap value as margins on flex items don't collapse */
  margin: 1rem;
  box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);
  overflow: hidden;
}

.gallery-image {
  display: block;
  width: auto;
  height: 100px;
  object-fit: cover;
  transition: -webkit-transform 400ms ease-out;
  transition: transform 400ms ease-out;
  transition: transform 400ms ease-out, -webkit-transform 400ms ease-out;
}

.gallery-image:hover {
  -webkit-transform: scale(1.15);
          transform: scale(1.15);
}

/* ########################################################################## */

.img-gallery-container {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.rec {
  color: #ec5867;
  font-weight: 700;
}

.Marquee {
  /* background-color: #202b37; */

  width: 100vw;
  box-sizing: border-box;
  padding: 1em;
  color: #fff;
  font-weight: 200;
  display: -webkit-flex;
  display: flex;
  display: flex;
  -o-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  overflow: hidden;
}
.Marquee-content {
  display: -webkit-flex;
  display: flex;
  display: flex;
  -webkit-animation: marquee 10s linear infinite running;
  animation: marquee 10s linear infinite running;
}
.Marquee-content:hover {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}
.Marquee-tag {
  margin: 0 0.5em;
  padding: 0.5em;
  background: rgba(255, 255, 255, 0.1);
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  -o-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -o-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  transition: all 0.2s ease;
}
.recruit {
  width: 250px;
}

.Marquee-tag .recruit-img {
  width: 200px;
}
.Marquee-tag:hover {
  background: rgba(255, 255, 255, 0.5);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  cursor: pointer;
}
@-webkit-keyframes marquee {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
  }
}
@keyframes marquee {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
  }
}

.givmar {
  margin: 0 40px;

  -webkit-align-items: center;

          align-items: center;
}

.abtlogo {
  max-width: 200px;
  max-height: 200px;
}
.setWidth {
  width: 40vw;
}

.trust {
    border-radius: 0% 0% 0% 0% / 0% 0% 0% 0% ;
    box-shadow: 20px 20px rgba(0,0,0,.15);
    transition: all .4s ease;
  }

.trust-image{
  box-shadow: 5px 5px 5px rgba(53, 53, 53, 0.15);
  transition: all .4s ease;
}

  .size-setting{
    max-width: 700px;
    height: 500px;
    position: relative;
    margin: auto;
  }

.mar{
  margin-left: 40px;
  margin-right: 40px;
}

 
  .our-team {
    padding: 30px 0 40px;
    margin-bottom: 30px;
    background-color: #f7f5ec;
    text-align: center;
    overflow: hidden;
    position: relative;
  }
  
  .our-team .picture {
    display: inline-block;
    height: 130px;
    width: 130px;
    margin-bottom: 50px;
    z-index: 1;
    position: relative;
  }
  
  .our-team .picture::before {
    content: "";
    width: 100%;
    height: 0;
    border-radius: 50%;
    background-color: #1369ce;
    position: absolute;
    bottom: 135%;
    right: 0;
    left: 0;
    opacity: 0.9;
    -webkit-transform: scale(3);
            transform: scale(3);
    transition: all 0.3s linear 0s;
  }
  
  .our-team:hover .picture::before {
    height: 100%;
  }
  
  .our-team .picture::after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #1369ce;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  
  .our-team .picture img {
    width: 100%;
    height: auto;
    border-radius: 50%;
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: all 0.9s ease 0s;
  }
  
  .our-team:hover .picture img {
    box-shadow: 0 0 0 14px #f7f5ec;
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
  }
  
  .our-team .title {
    display: block;
    font-size: 15px;
    color: #4e5052;
    text-transform: capitalize;
  }
  
  .our-team .social {
    width: 100%;
    padding: 0;
    margin: 0;
    background-color: #1369ce;
    position: absolute;
    bottom: -100px;
    left: 0;
    transition: all 0.5s ease 0s;
  }
  
  .our-team:hover .social {
    bottom: 0;
  }
  
  .our-team .social li {
    display: inline-block;
  }
  
  .our-team .social li a {
    display: block;
    padding: 10px;
    font-size: 17px;
    color: white;
    transition: all 0.3s ease 0s;
    text-decoration: none;
  }
  
  .our-team .social li a:hover {
    color: #1369ce;
    background-color: #f7f5ec;
  }
  
.mar{
    margin-left: 30px;
    margin-right: 30px;
}
.main {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-family: sans-serif;
}

.grid-container {
  -webkit-columns: 5 200px;
          columns: 5 200px;
  grid-column-gap: 1.5rem;
  -webkit-column-gap: 1.5rem;
          column-gap: 1.5rem;
  width: 90%;
  margin: 0 auto;
}
.grid-container div {
  width: 150px;
  margin: 0 1.5rem 1.5rem 0;
  display: inline-block;
  width: 100%;
  border: solid 2px black;
  padding: 5px;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  transition: all .25s ease-in-out;
}

.grid-container div img {
  width: 100%;
  border-radius: 5px;
  transition: all .25s ease-in-out;
}
.grid-container div p {
  margin: 5px 0;
  padding: 0;
  text-align: center;
  font-style: italic;
}

.activity-card {
    max-height: 100%;
    
}
.activity-content {
    position: absolute;
}

  
  .cr-img {
      object-fit: fill;
      width:25vw;
      height: 40vh;
      
  }
  .heading{
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      -webkit-flex-direction: column;
              flex-direction: column;
  }
  .main-card {
      border-top: 1px solid black;
  }
.navbar > .nav > .nav-link {
  color: #000;
}
.navbar > .nav > .active,
.navbar > .nav > .active > a:hover,
.navbar > .nav > .active > a:focus {
  background: #202b37;
  color: #fff;
}

.popup {  
    position: fixed;  
    width: 100%;  
    height: 100%;  
    top: 0;  
    left: 0;  
    right: 0;  
    bottom: 0;  
    margin: auto;  
    background-color: rgba(0, 0, 0, 0.411);  
    overflow-x: scroll;
    opacity: 1.5;        
  }  
  .popup\_inner {  
    position: absolute;  
    left: 25%;  
    right: 25%;  
    top: 25%;  
    bottom: 25%;  
    margin: auto;  
    border-radius: 20px;  
    background: white;  
    height: 100%;
  }
  .head{
      color: #ffffff;
  }

  .btn-block {
    width: 100%;
}
.uploaded-img{
    
}

.uploaded-img:hover{
    
    -webkit-filter: brightness(0.5);
    
            filter: brightness(0.5);
    transition: 1s ease-in-out;
}
.cse {
  /* background: linear-gradient(169deg, #000000, #e45050);
    background-size: 400% 400%;

    -webkit-animation: AnimationName 10s ease infinite;
    -moz-animation: AnimationName 10s ease infinite;
    -o-animation: AnimationName 10s ease infinite;
    animation: AnimationName 10s ease infinite; */
}

.deptcont {
  width: 95vw;
  margin: 0;
  padding: 0;
  margin-right: 10px;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 26% 0%;
  }
  50% {
    background-position: 75% 100%;
  }
  100% {
    background-position: 26% 0%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 26% 0%;
  }
  50% {
    background-position: 75% 100%;
  }
  100% {
    background-position: 26% 0%;
  }
}
.illustration-cont {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.outsource {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}


.words{
    font-family: Krona;
}

.hod{
    background: linear-gradient(169deg, #000000, #e45050);
    background-size: 400% 400%;

    -webkit-animation: AnimationName 10s ease infinite;
    animation: AnimationName 10s ease infinite;
}

@-webkit-keyframes AnimationName {
    0%{background-position:26% 0%}
    50%{background-position:75% 100%}
    100%{background-position:26% 0%}
}
@keyframes AnimationName {
    0%{background-position:26% 0%}
    50%{background-position:75% 100%}
    100%{background-position:26% 0%}
}
.rocket {
    position: absolute;
    top: 20%;
    width: 80px;
    left: calc(50% - 60px);
  }
  .rocket .rocket-body {
    width: 80px;
    left: calc(50% - 50px);
    -webkit-animation: bounce 0.5s infinite;
            animation: bounce 0.5s infinite;
  }
  .rocket .rocket-body .body {
    background-color: #dadada;
    height: 150px;
    left: calc(50% - 50px);
    border-top-right-radius: 100%;
    border-top-left-radius: 100%;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-top: 5px solid #f5f5f5;
  }
  .rocket .rocket-body:before {
    content: '';
    position: absolute;
    left: calc(50% - 24px);
    width: 48px;
    height: 13px;
    background-color: #554842;
    bottom: -13px;
    border-bottom-right-radius: 60%;
    border-bottom-left-radius: 60%;
  }
  .rocket .window {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: #a75248;
    left: calc(50% - 25px);
    top: 40px;
    border: 5px solid #b4b2b2;
  }
  .rocket .fin {
    position: absolute;
    z-index: -100;
    height: 55px;
    width: 50px;
    background-color: #a75248;
  }
  .rocket .fin-left {
    left: -30px;
    top: calc(100% - 55px);
    border-top-left-radius: 80%;
    border-bottom-left-radius: 20%;
  }
  .rocket .fin-right {
    right: -30px;
    top: calc(100% - 55px);
    border-top-right-radius: 80%;
    border-bottom-right-radius: 20%;
  }
  .rocket .exhaust-flame {
    position: absolute;
    top: 90%;
    width: 28px;
    background: linear-gradient(to bottom, transparent 10%, #f5f5f5 100%);
    height: 150px;
    left: calc(50% - 14px);
    -webkit-animation: exhaust 0.2s infinite;
            animation: exhaust 0.2s infinite;
  }
  .rocket .exhaust-fumes li {
    width: 60px;
    height: 60px;
    background-color: #f5f5f5;
    list-style: none;
    position: absolute;
    border-radius: 100%;
  }
  .rocket .exhaust-fumes li:first-child {
    width: 200px;
    height: 200px;
    bottom: -300px;
    -webkit-animation: fumes 5s infinite;
            animation: fumes 5s infinite;
  }
  .rocket .exhaust-fumes li:nth-child(2) {
    width: 150px;
    height: 150px;
    left: -120px;
    top: 260px;
    -webkit-animation: fumes 3.2s infinite;
            animation: fumes 3.2s infinite;
  }
  .rocket .exhaust-fumes li:nth-child(3) {
    width: 120px;
    height: 120px;
    left: -40px;
    top: 330px;
    -webkit-animation: fumes 3s 1s infinite;
            animation: fumes 3s 1s infinite;
  }
  .rocket .exhaust-fumes li:nth-child(4) {
    width: 100px;
    height: 100px;
    left: -170px;
    -webkit-animation: fumes 4s 2s infinite;
            animation: fumes 4s 2s infinite;
    top: 380px;
  }
  .rocket .exhaust-fumes li:nth-child(5) {
    width: 130px;
    height: 130px;
    left: -120px;
    top: 350px;
    -webkit-animation: fumes 5s infinite;
            animation: fumes 5s infinite;
  }
  .rocket .exhaust-fumes li:nth-child(6) {
    width: 200px;
    height: 200px;
    left: -60px;
    top: 280px;
    -webkit-animation: fumes2 10s infinite;
            animation: fumes2 10s infinite;
  }
  .rocket .exhaust-fumes li:nth-child(7) {
    width: 100px;
    height: 100px;
    left: -100px;
    top: 320px;
  }
  .rocket .exhaust-fumes li:nth-child(8) {
    width: 110px;
    height: 110px;
    left: 70px;
    top: 340px;
  }
  .rocket .exhaust-fumes li:nth-child(9) {
    width: 90px;
    height: 90px;
    left: 200px;
    top: 380px;
    -webkit-animation: fumes 20s infinite;
            animation: fumes 20s infinite;
  }
  
  .star li {
    list-style: none;
    position: absolute;
  }
  .star li:before, .star li:after {
    content: '';
    position: absolute;
    background-color: #f5f5f5;
  }
  .star li:before {
    width: 10px;
    height: 2px;
    border-radius: 50%;
  }
  .star li:after {
    height: 8px;
    width: 2px;
    left: 4px;
    top: -3px;
  }
  .star li:first-child {
    top: -30px;
    left: -210px;
    -webkit-animation: twinkle 0.4s infinite;
            animation: twinkle 0.4s infinite;
  }
  .star li:nth-child(2) {
    top: 0;
    left: 60px;
    -webkit-animation: twinkle 0.5s infinite;
            animation: twinkle 0.5s infinite;
  }
  .star li:nth-child(2):before {
    height: 1px;
    width: 5px;
  }
  .star li:nth-child(2):after {
    width: 1px;
    height: 5px;
    top: -2px;
    left: 2px;
  }
  .star li:nth-child(3) {
    left: 120px;
    top: 220px;
    -webkit-animation: twinkle 1s infinite;
            animation: twinkle 1s infinite;
  }
  .star li:nth-child(4) {
    left: -100px;
    top: 200px;
    -webkit-animation: twinkle 0.5s ease infinite;
            animation: twinkle 0.5s ease infinite;
  }
  .star li:nth-child(5) {
    left: 170px;
    top: 100px;
    -webkit-animation: twinkle 0.4s ease infinite;
            animation: twinkle 0.4s ease infinite;
  }
  .star li:nth-child(6) {
    top: 87px;
    left: -79px;
    -webkit-animation: twinkle 0.2s infinite;
            animation: twinkle 0.2s infinite;
  }
  .star li:nth-child(6):before {
    height: 1px;
    width: 5px;
  }
  .star li:nth-child(6):after {
    width: 1px;
    height: 5px;
    top: -2px;
    left: 2px;
  }
  
  @-webkit-keyframes fumes {
    50% {
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
      background-color: transparent;
    }
    51% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
    }
    100% {
      background-color:transparent;
      
      -webkit-transform: scale(1);
      
              transform: scale(1);
    }
  }
  
  @keyframes fumes {
    50% {
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
      background-color: transparent;
    }
    51% {
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
    }
    100% {
      background-color:transparent;
      
      -webkit-transform: scale(1);
      
              transform: scale(1);
    }
  }
  @-webkit-keyframes bounce {
    0% {
      -webkit-transform: translate3d(0px, 0px, 0);
              transform: translate3d(0px, 0px, 0);
    }
    50% {
      -webkit-transform: translate3d(0px, -4px, 0);
              transform: translate3d(0px, -4px, 0);
    }
    100% {
      -webkit-transform: translate3d(0px, 0px, 0);
              transform: translate3d(0px, 0px, 0);
    }
  }
  @keyframes bounce {
    0% {
      -webkit-transform: translate3d(0px, 0px, 0);
              transform: translate3d(0px, 0px, 0);
    }
    50% {
      -webkit-transform: translate3d(0px, -4px, 0);
              transform: translate3d(0px, -4px, 0);
    }
    100% {
      -webkit-transform: translate3d(0px, 0px, 0);
              transform: translate3d(0px, 0px, 0);
    }
  }
  @-webkit-keyframes exhaust {
    0% {
      background: linear-gradient(to bottom, transparent 10%, #f5f5f5 100%);
    }
    50% {
      background: linear-gradient(to bottom, transparent 8%, #f5f5f5 100%);
    }
    75% {
      background: linear-gradient(to bottom, transparent 12%, #f5f5f5 100%);
    }
  }
  @keyframes exhaust {
    0% {
      background: linear-gradient(to bottom, transparent 10%, #f5f5f5 100%);
    }
    50% {
      background: linear-gradient(to bottom, transparent 8%, #f5f5f5 100%);
    }
    75% {
      background: linear-gradient(to bottom, transparent 12%, #f5f5f5 100%);
    }
  }
  @-webkit-keyframes fumes2 {
    50% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
  }
  @keyframes fumes2 {
    50% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
    }
  }
  @-webkit-keyframes twinkle {
    80% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      opacity: 0.7;
    }
  }
  @keyframes twinkle {
    80% {
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      opacity: 0.7;
    }
  }

  .vm {
    background: linear-gradient(270deg, #c3bbc3, #68def2, #bdbf74);
    background-size: 600% 600%;

    -webkit-animation: AnimationName 11s ease infinite;
    animation: AnimationName 11s ease infinite;
}

@-webkit-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}

.main-container{
    font-family: 'Times New Roman', Times, serif;
}
.card-container {
	background-color: #231E39;
	border-radius: 5px;
	box-shadow: 0px 10px 20px -10px rgba(0,0,0,0.75);
	color: #B3B8CD;
	padding-top: 30px;
	position: relative;
	
	max-width: 100%;
	text-align: center;
}
p{
	left: 0;
	text-align: left;
}

.card-container .pro {
	color: #231E39;
	background-color: #FEBB0B;
	border-radius: 3px;
	font-size: 14px;
	font-weight: bold;
	padding: 3px 7px;	
}
.card-container .des {
	color: #231E39;
	background-color: #28f100;
	border-radius: 3px;
	font-size: 14px;
	font-weight: bold;
	padding: 3px 7px;	
}

.card-container .round {
	border: 1px solid #03BFCB;
	border-radius: 50%;
	padding: 7px;
}

.card-container .round:hover {
	-webkit-transform: scale(1.01);
	        transform: scale(1.01);
	transition: 1s all ease-in-out;
}

button.primary {
	background-color: #03BFCB;
	border: 1px solid #03BFCB;
	border-radius: 3px;
	color: #231E39;
	font-family: Montserrat, sans-serif;
	font-weight: 500;
	padding: 10px 25px;
}

button.primary.ghost {
	background-color: transparent;
	color: #02899C;
}

button.primary.ghost:hover {
	background-color: #02899C;
	border-color: transparent;
	color: white;
}

@-webkit-keyframes increaseSize {
	20%{width: 20%;}
	30%{width: 30%;}
	40%{width: 40%;}
	50%{width: 50%;}
	60%{width: 60%;}
	70%{width: 70%;}
	80%{width: 80%;}
	90%{width: 90%;}
	100%{width: 100%;}
	to{width: 100%;}
}

@keyframes increaseSize {
	20%{width: 20%;}
	30%{width: 30%;}
	40%{width: 40%;}
	50%{width: 50%;}
	60%{width: 60%;}
	70%{width: 70%;}
	80%{width: 80%;}
	90%{width: 90%;}
	100%{width: 100%;}
	to{width: 100%;}
}

.skills {
	background-color: #1F1A36;
	text-align: left;
	padding: 15px;
	margin-top: 30px;
}

.skills ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

.skills ul li {
	border: 1px solid #2D2747;
	border-radius: 2px;
	display: inline-block;
	font-size: 12px;
	margin: 0 7px 7px 0;
	padding: 7px;
}



/* ================new card===================== */
.padding {
    padding: 2rem !important
}

.user-card-full {
    overflow: hidden
}

.card {
    border-radius: 5px;
    box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
    border: none;
    margin-bottom: 30px
}

.m-r-0 {
    margin-right: 0px
}

.m-l-0 {
    margin-left: 0px
}

.user-card-full .user-profile {
    border-radius: 5px 0 0 5px
}

.bg-c-lite-green {
    background: linear-gradient(to right, #ee5a6f, #f29263)
}

.user-profile {
    padding: 20px 0
}

.card-block {
    padding: 1.25rem
}

.m-b-25 {
    margin-bottom: 25px
}

.img-radius {
    border-radius: 5px
}

h6 {
    font-size: 14px
}

.card .card-block p {
    line-height: 25px
}

@media only screen and (min-width: 1400px) {
    p {
        font-size: 14px
    }
}

.card-block {
    padding: 1.25rem
}

.b-b-default {
    border-bottom: 1px solid #e0e0e0
}

.m-b-20 {
    margin-bottom: 20px
}

.p-b-5 {
    padding-bottom: 5px !important
}

.card .card-block p {
    line-height: 25px
}

.m-b-10 {
    margin-bottom: 10px
}

.text-muted {
    color: #919aa3 !important
}

.b-b-default {
    border-bottom: 1px solid #e0e0e0
}

.f-w-600 {
    font-weight: 600
}

.m-b-20 {
    margin-bottom: 20px
}

.m-t-40 {
    margin-top: 20px
}

.p-b-5 {
    padding-bottom: 5px !important
}

.m-b-10 {
    margin-bottom: 10px
}

.m-t-40 {
    margin-top: 20px
}

.user-card-full .social-link li {
    display: inline-block
}

.user-card-full .social-link li a {
    font-size: 20px;
    margin: 0 10px 0 0;
    transition: all 0.3s ease-in-out
}

.openFile{
	cursor: pointer;
}
.itag {
    opacity: 0;
    font-size: 18px;
    color: #fff;
    will-change: transform;
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    transition: all 0.2s ease;
}
.heading2 {
    pointer-events: none;
}
.container2 {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.container2 .card3 {
    position: relative;
    width: 100%;
    height: 300px;
    background-color: #fff;
    overflow: hidden;
    margin-bottom: 4px;
}
.container2 .card3:before {
    content: "";
    z-index: 99;
    position: absolute;
    top: -10px;
    left: 32px;
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    background-color: #e6e5e1;
}
.container2 .card3:after {
    content: "";
    z-index: 99;
    position: absolute;
    bottom: -10px;
    left: 32px;
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    background-color: #e6e5e1;
}
.container2 .card3 .unlist {
    z-index: 99;
    position: absolute;
    left: 39px;
    top: 5px;
    list-style-type: none;
}
.container2 .card3 .unlist .listi {
    width: 2px;
    height: 2px;
    border-radius: 2px;
    margin: 6px 0;
    background-color: #e6e5e1;
}
.container2 .card3 .heading2 {
    z-index: 99;
    font-family: "Poppins", sans-serif;
    position: absolute;
    bottom: 0;
    font-size: 50px;
    font-weight: 700;
    color: #fff;
}
.container2 .card3 .fa-arrow-right {
    z-index: 100;
    position: absolute;
    right: 75px;
    bottom: 25px;
    font-size: 40px;
    cursor: pointer;
}
.container2 .card3 .pic {
    z-index: 100;
    width: 100%;
    height: 100%;
    -webkit-align-items: center;
            align-items: center;
    background-image: url("https://images.unsplash.com/photo-1528785198459-ec50485704c7?ixlib=rb-0.3.5&s=3a2fc3039516555bbb2e9cd2967bd321&auto=format&fit=crop&w=1537&q=80");
    background-size: 100% 100%;
    /* filter: grayscale(80%); */
}
.container2 .card3 .social {
    position: absolute;
    left: 60px;
    top: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    -webkit-align-items: center;
            align-items: center;
    width: 180px;
    height: 64px;
    border-radius: 80px;
}
.container2 .card3 .social i:nth-of-type(1) {
    transition-delay: 0.4s;
}
.container2 .card3 .social i:nth-of-type(2) {
    transition-delay: 0.3s;
}
.container2 .card3 .social i:nth-of-type(3) {
    transition-delay: 0.2s;
}
.container2 .card3 .social i:nth-of-type(4) {
    transition-delay: 0.1s;
}
.container2 .card3:hover .itag {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.container2 .card3 .butn {
    position: absolute;
    right: 14px;
    bottom: 14px;
    width: 30px;
    height: 30px;
    background-color: #da4d1d;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    outline: none;
    transition: all 0.3s ease;
    mix-blend-mode: hard-light;
}
.container2 .card3 .butn .itag {
    font-size: 3rem;
}
.container2 .card3:hover .butn {
    -webkit-transform: scale(16.5);
            transform: scale(16.5);
}
.container2 .card3:hover p {
    color: #fff;
}
.container2 .card3:hover .pic {
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
}
.container2 .card2 .butn {
    background-color: #2b26c3;
}
.dr {
    position: absolute;
    bottom: 16px;
    right: 16px;
    width: 100px;
}

.peos {
    background: linear-gradient(128deg, #65bff2, #b5cbd7, #dae5ec, #d4bbe3, #ca97e8, #ba63ee);
    background-size: 1200% 1200%;

    -webkit-animation: AnimationName 8s ease infinite;
    animation: AnimationName 8s ease infinite;
}

@-webkit-keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes AnimationName {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
/* ===============================Modal CSS==================================== */
@-webkit-keyframes modalo {
  from {
    width: 0;
    height: 0;
  }
  to {
    width: 100%;
    height: 100%;
  }
}
@keyframes modalo {
  from {
    width: 0;
    height: 0;
  }
  to {
    width: 100%;
    height: 100%;
  }
}

.modal {
  position: fixed;
  z-index: 20000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.541);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-animation-name: modalo;
          animation-name: modalo;
  -webkit-animation-duration: 0.2s;
          animation-duration: 0.2s;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  padding: 0;
}

.closed-modal {
  display: none;
}

.modal-content {
  width: 95%;
  height: 80%;
  background: transparent;
  border: none;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.modal-img {
  /* width: 80%;
    height: 100%;
    display: block; */
  width: 70%;
}
@media (max-width: 700) {
  .modal-content {
    top: 30%;
    width: 100%;
    height: 80%;
  }
  .modal-img {
    /* width: 80%;
      height: 100%;
      display: block; */
    height: auto;
  }
}

.close-btn {
  margin-top: 10px;
  margin-right: 6px;
  position: fixed;
  top: 0px;
  right: 0;
  color: #fff;
}
.download-btn {
  margin-top: 70px;
  margin-right: 6px;
  right: 0;
  top: 0;
  position: fixed;
  color: #fff;
  z-index: 10000;
}
.more-info {
  position: fixed;
  bottom: 10px;
}
.noti {
  z-index: 60000;
}

.pdf-modal{
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.377);
    position: fixed;
    overflow: scroll;
    z-index: 80000;
}
.close-btn {
    margin-right: 6px;
    position: fixed;
    top: 0;
    right: 0;
    color: #fff;
    z-index: 80001;
  }
.pdf-modal{
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.377);
    position: fixed;
    overflow: scroll;
    z-index: 80000;
}
.close-btn {
    margin-right: 6px;
    position: fixed;
    top: 0;
    right: 0;
    color: #fff;
    z-index: 80001;
  }
* {
  margin: 0;
  padding: 0;
}
.contact {
  padding: 4%;
  /* height: 400px; */
}
.col-md-3 {
  background: #202b37;
  padding: 4%;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.contact-info {
  margin-top: 10%;
  overflow: hidden;
}
.contact-info img {
  margin-bottom: 15%;
}
.contact-info h2 {
  margin-bottom: 10%;
  overflow: hidden;
}
.contact-info h4 {
  overflow: hidden;
}
.col-md-9 {
  background: #fff;
  padding: 3%;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.contact-form label {
  font-weight: 600;
}
.contact-form button {
  background: #25274d;
  color: #fff;
  font-weight: 600;
  width: 25%;
}
.contact-form button:focus {
  box-shadow: none;
}
.contact-submit {
  background: #202b37;
  color: #fff;
}
.contact-submit:hover {
  color: #fff;
}
/* MAP CSS ********************* */

.cont {
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: stretch;
          align-items: stretch;
}

.address {
  background: url(/static/media/contact.1e489006.jpg);
  background-size: cover;
  padding: 8% 8%;
  z-index: 2;
  height: 90vh;
}
.address::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
}

@media (max-width: 992px) {
  .address {
    width: 40%;
  }
}

@media (max-width: 768px) {
  .address {
    width: 100%;
  }
}

.address-data {
  color: #fff;
  margin-bottom: 8%;
}
.add-title {
  font-size: 150%;
  font-family: "Poppins", sans-serif;
  margin-bottom: 1%;
}
.add-desc {
  font-size: 100%;
  font-family: "Montserrat", sans-serif;
  color: #c2c2c2;
}

.map {
  height: 500px;
  width: 500px;
}

.admincard {
  margin: 20px;
}

* {
  margin: 0;
  padding: 0;
}
.checkbox {
  border-radius: 10%;
}
.form .button,
.form .message,
.customSelect,
.form .select,
.form .textarea,
.form .text-input,
.form .option-input + label,
.form .checkbox-input + label,
.form .label {
  padding: 0.75em 1em;
  -webkit-appearance: none;
  appearance: none;
  outline: none;
  line-height: normal;
  border-radius: 0;
  border: none;
  background: none;
  display: block;
}
.form .checkbox-input + label {
  border-radius: 50%;
}
.form .label {
  font-weight: bold;
  color: #fff;
  padding-top: 0;
  padding-left: 0;
  letter-spacing: 0.025em;
  font-size: 1.125em;
  line-height: 1.25;
  position: relative;
  z-index: 100;
}
.required .form .label:after,
.form .required .label:after {
  content: "*";
  color: #000000;
  font-weight: normal;
  font-size: 0.75em;
  vertical-align: top;
}

.customSelect,
.form .select,
.form .textarea,
.form .text-input,
.form .option-input + label,
.form .checkbox-input + label {
  font: inherit;
  line-height: normal;
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  color: #000;
  position: relative;
}

.bgccc {
  background-color: #202b37;
  /* background-color: #484349; */
  /* background: rgb(255, 60, 56);
  background: linear-gradient(
    180deg,
    rgba(255, 60, 56, 1) 0%,
    rgba(255, 151, 148, 1) 40%,
    rgba(255, 255, 255, 1) 100%
  ); */
  margin: 50px 400px;
  padding: 30px;
  border-radius: 40px;
}

@media (max-width: 991px) {
  .bgccc {
    margin: 15px 0px;
    border-radius: 10px;
    padding: 30px 10px;
  }
}

.customSelect:placeholder,
.form .select:placeholder,
.form .textarea:placeholder,
.form .text-input:placeholder,
.form .option-input + label:placeholder,
.form .checkbox-input + label:placeholder {
  color: white;
}
.customSelect:-webkit-autofill,
.form .select:-webkit-autofill,
.form .textarea:-webkit-autofill,
.form .text-input:-webkit-autofill,
.form .option-input + label:-webkit-autofill,
.form .checkbox-input + label:-webkit-autofill {
  box-shadow: 0 0 0px 1000px #111111 inset;
  -webkit-text-fill-color: white;
  border-top-color: #111111;
  border-left-color: #111111;
  border-right-color: #111111;
}

.form .message {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 100;
  font-size: 0.625em;
  color: white;
}

.form .option-input,
.form .checkbox-input {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.form .option-input + label,
.form .checkbox-input + label {
  display: inline-block;
  width: auto;
  color: #4e4e4e;
  position: relative;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
.form .option-input:focus + label,
.form .checkbox-input:focus + label,
.form .option-input:active + label,
.form .checkbox-input:active + label {
  color: #4e4e4e;
}
.form .option-input:checked + label,
.form .checkbox-input:checked + label {
  color: white;
  background-color: #ff3c38;
}

.form .button {
  font: inherit;
  line-height: normal;
  cursor: pointer;
  background: #ff3c38;
  color: white;
  font-weight: bold;
  width: auto;
  margin-left: auto;
  font-weight: bold;
  padding-left: 3em;
  padding-right: 3em;
  border-radius: 10px;
}
.form .button:hover,
.form .button:focus,
.form .button:active {
  color: white;
  border-color: white;
}
.form .button:active {
  position: relative;
  top: 1px;
  left: 1px;
}

.form {
  max-width: 40em;
  margin: 0 auto;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}
.form .field {
  width: 100%;
  margin: 0 0 1.5em 0;
}
@media screen and (min-width: 40em) {
  .form .field.half {
    width: calc(50% - 1px);
  }
}
.form .field.last {
  margin-left: auto;
}
.form .textarea {
  max-width: 100%;
}
.form .select {
  text-indent: 0.01px;
  text-overflow: "" !important;
}
.form .select::-ms-expand {
  display: none;
}
.form .checkboxes,
.form .options {
  padding: 0;
  margin: 0;
  list-style-type: none;
  overflow: hidden;
}
.form .checkbox,
.form .option {
  float: left;
  margin: 1px;
}
.customSelect {
  pointer-events: none;
}
.customSelect:after {
  content: "";
  pointer-events: none;
  width: 0.5em;
  height: 0.5em;
  border-style: solid;
  border-color: white;
  border-width: 0 3px 3px 0;
  position: absolute;
  top: 50%;
  margin-top: -0.625em;
  right: 1em;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.customSelect.customSelectFocus:after {
  border-color: white;
}
.magic-focus {
  position: absolute;
  z-index: 0;
  width: 0;
  pointer-events: none;
  background: rgba(255, 255, 255, 0.15);
  transition: top 0.2s, left 0.2s, width 0.2s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  will-change: top, left, width;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}

.pdf-modal {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.377);
  position: fixed;
  overflow: scroll;
  z-index: 80000;
}
.close-btn {
  margin-right: 6px;
  position: fixed;
  top: 0;
  right: 0;
  color: #fff;
  z-index: 80001;
}

